<!--
 * @Author: 智客云网络科技
 * @Date: 2022-02-13 11:52:23
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 09:36:00
 * @Description: 出库扫描
 * @FilePath: \src\views\StockManage\Deliver.vue
-->

<template>
  <div class="kc-app">
    <van-nav-bar
      title="出库扫描"
      left-arrow
      :fixed="true"
      :placeholder="true"
      :z-index="500"
      :safe-area-inset-top="true"
      @click-left="$router.go(-1)"
    />

    <van-field
      v-model="express_id"
      label="快递单号"
      ref="express_input_dom"
      name="express_input_dom"
      autocomplete="off"
      @blur="expressIdInputBlur()"
      @click="expressIdInputGainFocus(1)"
      placeholder="等待单号输入"
    />

    <!-- 状态显示框 -->
    <div class="state-box">
      <p class="state">
        当前状态：
        <span class="dd" v-if="state == 1">等待输入...</span>
        <span class="sb" v-if="state == 2">单号识别...</span>
        <span class="sc" v-if="state == 3">数据上传...</span>
      </p>
    </div>

    <!-- 日志列表 -->
    <div class="log-list">
      <div class="log-title">工作日志</div>

      <div v-if="logList.length < 1" class="log-null">等待数据...</div>

      <div class="log-con" v-for="(item, i) in logList" :key="i">
        <div class="log-cb">
          <div class="expressid">[{{ item.index }}] {{ item.express_id }}</div>
          <div class="log-state log-done" v-if="item.state == 1">
            【出库成功】
          </div>
          <div class="log-state log-fail" v-else-if="item.state == 2">
            【单号已存在】
          </div>
          <div class="log-state log-fail" v-else>【服务器错误】</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cargoDeliver } from "./api";
import { getCurrentInstance, watch, onMounted, ref } from "vue";
export default {
  setup() {
    const internalInstance = getCurrentInstance();
    const utils = internalInstance.appContext.config.globalProperties.$utils;
    const express_input_dom = ref(null); //Dom对象
    const warehouse_name = ref(""); //仓库名称
    const warehouse_id = ref(""); //仓库ID
    const express_id = ref(""); //快递单号
    const state = ref(1); //状态 1:等待单号 2:单号识别 3:数据上传
    const upload = ref(false); //当前是否为提交状态
    const logList = ref([]); //日志列表
    const showWarehousePicker = ref(false); //控制仓库选择是否显示

    //监听单号变化
    watch(
      () => express_id.value,
      (val) => {
        // 延迟1秒执行
        setTimeout(() => {
          if (val !== "") {
            if (val.length >= 6 && val.length <= 20) {
              state.value = 3; //改写状态
              if (upload.value == false) {
                let jq = val.substr(0, 4);
                if (jq !== "CNFY") {
                  upload.value = true; //修改当前状态为提交中
                  deliver(); //提交出库
                } else {
                  utils.playAudio(require("@/assets/audio/error.mp3"));
                  utils.fail("编码不受支持！", 800);
                  initExpressInput(); //初始化
                }
              }
            } else {
              utils.playAudio(require("@/assets/audio/error.mp3"));
              utils.fail("操作失败！", 800);
              initExpressInput(); //初始化
              return false;
            }
          }
        }, 100);
      }
    );

    //单号输入框失焦时触发
    const expressIdInputBlur = () => {
      expressIdInputGainFocus(1);
    };

    //控制输入框获取焦点
    const expressIdInputGainFocus = (force = false) => {
      if (force) {
        //找到input的dom对象
        const inpdom =
          express_input_dom.value.$el.children[1].children[0].children[0];
        inpdom.setAttribute("readonly", "readonly");
        inpdom.focus();
        setTimeout(() => {
          inpdom.removeAttribute("readonly");
        }, 100);
      }
    };

    //初始化快递单号输入框
    const initExpressInput = () => {
      express_id.value = "";
      state.value = 1;
    };

    //提交出库请求
    const deliver = () => {
      state.value = 3; //改写状态

      //特殊符号检测
      var pattern = /^[A-Za-z0-9\\-]{6,20}$/; //过滤除英文数字意外的所有字符 限制长度6-30位
      if (!pattern.test(express_id.value)) {
        utils.playAudio(require("@/assets/audio/error.mp3"));
        utils.fail("快递单号不能包含特殊字符！", 800);
        //修改当前状态为提交结束
        setTimeout(() => {
          upload.value = false;
          initExpressInput(); //初始化
        }, 200);
        return;
      }

      //准备提交
      const params = {
        kec_id: express_id.value,
      };
      cargoDeliver(params)
        .then((res) => {
          utils.playAudio(require("@/assets/audio/deliver_success.mp3"));
          utils.success(res.err_msg, 200);
          state.value = 1;
          addLog(1);
          //修改当前状态为提交结束
          setTimeout(() => {
            upload.value = false;
            initExpressInput(); //初始化
          }, 200);
        })
        .catch((err) => {
          utils.fail(err.err_msg, 800);
          state.value = 1;
          if (err.code == "150017") {
            utils.playAudio(require("@/assets/audio/repeat.mp3"));
            addLog(2);
          } else {
            utils.playAudio(require("@/assets/audio/error.mp3"));
            addLog(3);
          }
          //修改当前状态为提交结束
          setTimeout(() => {
            upload.value = false;
            initExpressInput(); //初始化
          }, 200);
        });
    };

    //添加工作日志
    const addLog = (state) => {
      var loglength = logList.value.length;
      if (loglength >= 10) {
        logList.value.shift(); //删除第一个日志
      }
      let index = 1;
      if (loglength > 0) {
        let log = logList.value;
        index = log[log.length - 1]["index"] + 1;
      }
      logList.value.push({
        index: index,
        express_id: express_id.value,
        state: state,
      });
    };

    onMounted(() => {
      expressIdInputGainFocus(1);
    });

    return {
      express_input_dom,
      expressIdInputBlur,
      expressIdInputGainFocus,
      express_id,
      state,
      logList,
      warehouse_name,
      warehouse_id,
      showWarehousePicker,
    };
  },
};
</script>

<style>
.nut-input.nut-input-border {
  border: none;
}
</style>
<style scoped>
.concent-box {
  width: 100%;
  /* position: absolute; */
}

.state-box {
  margin: auto;
  padding: 0 15px;
  padding-top: 12px;
  background-color: #ffffff;
}

.state {
  font-size: 14px;
  color: #000000;
  font-weight: 600;
  padding-bottom: 12px;
  border-bottom: 1px solid #cecece36;
}

.state span {
  font-weight: normal;
}

.state .dd {
  color: #ffae00;
}

.state .sb {
  color: #001799;
}

.state .sc {
  color: #00dac7;
}

.state .done {
  color: #36e900;
}

.state .fail {
  color: #d80000;
}

.log-list {
  margin: auto;
  min-height: 235px;
  padding: 15px 10px;
  background-color: #ffffff;
}

.log-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #000000;
}

.log-null {
  margin: auto;
  padding: 5px 15px;
  font-size: 14px;
  color: #4b4b4b;
}

.log-con {
  width: 100%;
  margin: auto;
}

.log-cb {
  display: flex;
  padding: 2px 5px;
}

.orderid {
  color: #999999;
  font-size: 14px;
  line-height: 18px;
}

.log-state {
  font-size: 14px;
  padding-left: 5px;
  line-height: 18px;
}

.log-done {
  color: #2bb900;
  font-weight: 600;
}

.log-fail {
  color: #d80000;
  font-weight: 600;
}
</style>
